import { useState, useEffect, useContext } from 'react';
import { NavLink, Link, useParams } from "react-router-dom";

import { NotificationsContext } from '../../../components/app/contexts/NotificationsContext';
import { ApiContext } from '../../../components/app/contexts/ApiContext';

const headings = {
  'create': 'Создать настройки',
  'update': 'Редактировать настройки',
}


function SettingModalScreen(props) {

  const notifications = useContext(NotificationsContext);
  const api = useContext(ApiContext);

  const settingId = props.settingId;

  const settings = props.settings;

  var tempSetting = '';
  var tempValue = '';
  var tempType = '';
  if(settingId > 0){
    settings.forEach((item, i) => {
      if(item.id == settingId){
        tempSetting = item.setting;
        tempValue = item.value;
        tempType = item.type;
      }
    });
  }

  const [setting, setSetting] = useState(tempSetting);
  const [settingValue, setSettingValue] = useState(tempValue);
  const [type, setType] = useState(tempType);
  const [modal, setModal] = props.modal;
  const getData = props.getData;

  const settingsMap = props.settingsMap;
  const typesMap = props.typesMap;

  const heading = props.settingId > 0 ? headings.update : headings.create;

  var modalStateClass = ' uk-open';


  const handleInputChange = (e) => {
    var {id, value} = e.target;

    if(id === 'settingValue'){
      setSettingValue(value);
    }

  }


  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    if(settingId > 0){
      const response = await api.settings.update(
        settingId,
        settingValue
      );

      if(response.status === true){
        notifications.settings.notification('setting_updated');

        setTimeout(async function(){
          await getData();
          await handleCancel(e);
        }, 2500);
      } else {
        notifications.settings.notification(response.error);
      }

    }

    return false;
  }


  const handleCancel = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setModal([]);
    return false;
  }

  function renderValue(){
    var result = [];

    if(type == 'int'){
      result = (
        <div className="uk-margin">
          <label className="uk-form-label" htmlFor="settingValue">Значение:</label>
          <div className="uk-form-controls">
            <input
              id="settingValue"
              name="settingValue"
              value={settingValue}
              type="number"
              step="1"
              onChange={handleInputChange}
              className="uk-input"
              placeholder={typesMap[type]}
              required={true}
            />
          </div>
        </div>
      );
    } else if(type == 'float' || type == 'double'){
      result = (
        <div className="uk-margin">
          <label className="uk-form-label" htmlFor="settingValue">Значение:</label>
          <div className="uk-form-controls">
            <input
              id="settingValue"
              name="settingValue"
              value={settingValue}
              type="number"
              step="0.1"
              onChange={handleInputChange}
              className="uk-input"
              placeholder={typesMap[type]}
              required={true}
            />
          </div>
        </div>
      );
    } else if(type == 'string'){
      result = (
        <div className="uk-margin">
          <label className="uk-form-label" htmlFor="settingValue">Значение:</label>
          <div className="uk-form-controls">
            <textarea
              id="settingValue"
              name="settingValue"
              value={settingValue}
              onChange={handleInputChange}
              className="uk-textarea"
              placeholder={typesMap[type]}
              required={true}
            >
            </textarea>
          </div>
        </div>
      );
    } if(type == 'bool' || type == 'boolean'){
      result = (
        <div className="uk-margin">
          <label className="uk-form-label" htmlFor="settingValue">Значение:</label>
          <div className="uk-form-controls">
            <select
              id="settingValue"
              name="settingValue"
              value={settingValue}
              onChange={handleInputChange}
              className="uk-select"
              required={true}
            >
              <option value="0">False</option>
              <option value="1">True</option>
            </select>
          </div>
        </div>
      );
    }

    return result;
  }

  return (

  <div id="settings-modal" className={"uk-modal" + modalStateClass} data-uk-modal style={{display: 'block'}}>
    <div className="uk-modal-dialog">
      <form id="hierModalForm" className="uk-form-stacked" onSubmit={handleSubmit}>
        <button className="uk-modal-close-default" data-uk-close type="button" onClick={handleCancel}></button>
        <div className="uk-modal-header">
          <h2 className="uk-modal-title">{heading}</h2>
        </div>
        <div className="uk-modal-body">

          <p>{settingsMap[setting]}</p>

          {renderValue()}

        </div>
        <div className="uk-modal-footer uk-text-right">
          <button className="uk-button uk-button-default" type="button" onClick={handleCancel}>Отмена</button>
          <button className="uk-button uk-button-primary custom-button-success" type="submit">Сохранить</button>
        </div>
      </form>
    </div>
  </div>

  )
}

export default SettingModalScreen;
